/* eslint-disable max-len */
/* eslint-disable no-useless-constructor */
class SliderLogos extends HTMLElement {
  constructor() {
    super();
    this.sprungMarke = this.dataset.slidePush;
  }

  connectedCallback() {
    this.getElements();
    this.addEvents();
    this.controls.setAttribute('active', '');
    this.observeControls();
  }

  getElements() {
    this.slider = this.querySelector('.logo-list');
    this.logoBox = this.querySelector('.logo-box');
    this.controls = this.querySelector('.controls');
    this.next = this.querySelector('.next');
    this.prev = this.querySelector('.prev');
  }

  addEvents() {
    this.next.addEventListener('click', () => {
      this.scrollToPosition(this.slider.scrollLeft + this.logoBox.offsetWidth * this.sprungMarke);
    });
    this.prev.addEventListener('click', () => {
      this.scrollToPosition(this.slider.scrollLeft - this.logoBox.offsetWidth * this.sprungMarke);
    });
  }

  scrollToPosition(x) {
    this.slider.scrollTo({
      top: 0,
      left: x,
      behavior: 'smooth'
    });
  }

  static getContainerHeight(target) {
    return target.offsetHeight();
  }

  observeControls() {
    this.sliderItemFirst = this.slider.querySelector('.logo-box:first-of-type');
    this.sliderItemLast = this.slider.querySelector('.logo-box:last-of-type');
    this.options = {
      root: this.slider,
      threshold: [0.8, 1]
    };
    this.observer = new IntersectionObserver((entries) => {
      this.next.style.display = 'block';
      this.prev.style.display = 'block';
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 1 && entry.target === this.sliderItemFirst) {
          this.prev.style.display = 'none';
        }
        if (entry.isIntersecting && entry.intersectionRatio >= 1 && entry.target === this.sliderItemLast) {
          this.next.style.display = 'none';
        }
      });
    }, this.options);
    this.observer.observe(this.sliderItemFirst);
    this.observer.observe(this.sliderItemLast);
  }
}

if (!customElements.get('slider-logos')) {
  customElements.define('slider-logos', SliderLogos);
}
